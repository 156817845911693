// store/login.js

export const actions = {
  // User submitted form
  async loginUser({ dispatch }, { data, location }) {
    try {
      // Perform login
      await this.$auth.loginWith('local', {
        data: { ...data, location_slug: location },
      })

      // Extract user details
      const { company_id: id, subdomain, locations } = this.$auth.user

      // Set company data
      await dispatch(
        'locations/setCompanyData',
        { id, subdomain, locations },
        { root: true },
      )

      // Return success
      return { success: true }
    }
    catch (error) {
      // Handle error (could be a network issue or bad credentials)
      return { success: false, error }
    }
  },
  async validateToken({ dispatch }, token) {
    try {
      this.$auth.setToken('local', `Bearer ${token}`)
      localStorage.setItem('token', token)

      await this.$auth.fetchUser()

      if (!this.$auth.user)
        return { success: false, error: 'User not found!' }

      const { company_id: id, subdomain, locations } = this.$auth.user

      await dispatch(
        'locations/setCompanyData',
        { id, subdomain, locations },
        { root: true },
      )

      return { success: true }
    }
    catch (error) {
      return { success: false, error }
    }
  },

  // user successfully logged-in
  async navigateAfterLogin() {
    try {
      const first_login = this.$auth.user.first_login

      if (first_login && this.$auth.user.is_company_owner === true) {
        localStorage.setItem('first_login', 1)
      }

      this.$router.push('/location-select')
    }
    catch (error) {
      // user will log in to site, but not directly to pos screen
      throw new Error(error)
    }
  },

  // login and go directly to pos screen
  async navigateWithTerminal({ dispatch }, terminal) {
    try {
      const lockStatus = await dispatch('terminals/lock', terminal, {
        root: true,
      })

      if (!lockStatus) {
        // Handle terminal lock failure
        throw new Error('Terminal in use')
      }

      await dispatch('ui/openCustomerSearchModal', null, { root: true })

      this.$router.push(`/`)
    }
    catch (error) {
      // user will log in to site, but not directly to pos screen
      throw new Error(error)
    }
  },

  // go directly to terminals since there is no reason to show locations
  navigateSingleLocation(_, slug) {
    this.$router.push(`/terminals/${slug}`)
  },
}
