<script>
import AppButton from '../components/common/AppButton.vue'
import ManagerLogin from '../components/ManagerLogin'
// import ClockInOut from '../components/ClockInOut'
import { isNullOrUndefined } from '../utils'
import Logo from '~/assets/images/logo.svg'

export default {

  components: {
    AppButton,
    ManagerLogin,
  },
  data: () => ({
    backgroundImage: `url(${require('~/assets/images/background.svg')})`,
    Logo,
  }),
  computed: {
    storeName() {
      const { slug } = this.$route.params
      if (isNullOrUndefined(slug))
        return ''

      return slug.split('-').join(' ') || ''
    },
    url() {
      if (this.$auth.user) {
        return `/location-select`
      }

      return '/'
    },
    isLoginPage() {
      return this.$route.fullPath.includes('login')
    },
    isTerminalsPage() {
      return this.$route.fullPath.includes('terminals')
    },
  },
}
</script>

<template>
  <div :style="{ backgroundImage }" class="bg-bottom bg-no-repeat bg-cover">
    <div class="flex items-center justify-center min-h-screen">
      <div class="absolute top-0 left-0 pt-10 pl-10 select-none">
        <a :href="url"><img alt="" width="136" height="15" :src="Logo"></a>
      </div>

      <div class="absolute top-0 right-0 mr-1 text-center select-none">
        <h3
          class="w-2/3 pt-6 text-2xl leading-none tracking-wide uppercase font-mohave text-turquoise-dark"
        >
          <nuxt-link to="/">
            {{ storeName }}
          </nuxt-link>
        </h3>
      </div>

      <!-- Main Content Goes Here -->
      <nuxt />

      <!-- Bottom Content Goes Here -->
      <div class="absolute bottom-0 right-0 flex w-full px-10 pb-10">
        <div v-if="!isLoginPage" class="flex justify-between w-full">
          <ManagerLogin />

          <!-- if manager needs to login to a terminal in a different store -->
          <AppButton
            v-if="$store.getters.isManager && isTerminalsPage"
            outline
            variant="white"
            class="text-xs font-semibold leading-none"
            @click.native="$router.push('/location-select')"
          >
            Stores
          </AppButton>
          <!-- <AppButton
            outline
            variant="white"
            class="text-xs font-semibold leading-none"
          >
            <nuxt-link to="/">Go to last pos</nuxt-link>
          </AppButton> -->

          <!-- <ClockInOut /> -->
        </div>
      </div>
    </div>
  </div>
</template>
