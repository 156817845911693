<script>
import CustomerSearchItem from './CustomerSearchItem'

export default {
  name: 'CustomerSearchResult',
  components: { CustomerSearchItem },
  props: {
    value: { type: Object, default: null },
    customers: { type: Array, required: true },
  },
  computed: {
    hasSelected() {
      return !!this.value
    },
  },
  methods: {
    isSelected(customer) {
      return this.hasSelected && this.value.id === customer.id
    },
  },
}
</script>

<template>
  <ul class="h-32 mb-8 overflow-auto border rounded">
    <CustomerSearchItem
      v-for="customer in customers"
      :key="customer.id"
      :customer="customer"
      :selected="isSelected(customer)"
      class="p-2 cursor-pointer hover:bg-grey-lighter"
      @click.native="$emit('input', customer)"
    />
  </ul>
</template>
