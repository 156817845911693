import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _42ada9c4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _9d729cc4 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _631b1586 = () => interopDefault(import('../pages/customers/index/create.vue' /* webpackChunkName: "pages/customers/index/create" */))
const _faa727e8 = () => interopDefault(import('../pages/customers/index/_id/index.vue' /* webpackChunkName: "pages/customers/index/_id/index" */))
const _8468f2ec = () => interopDefault(import('../pages/employee-schedules/index.vue' /* webpackChunkName: "pages/employee-schedules/index" */))
const _5bdfe8ce = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _ded46fe6 = () => interopDefault(import('../pages/employees/index/create.vue' /* webpackChunkName: "pages/employees/index/create" */))
const _e1633188 = () => interopDefault(import('../pages/employees/index/_id/index.vue' /* webpackChunkName: "pages/employees/index/_id/index" */))
const _31fa571a = () => interopDefault(import('../pages/location-select.vue' /* webpackChunkName: "pages/location-select" */))
const _16ddd532 = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _6fe22558 = () => interopDefault(import('../pages/locations/index/create.vue' /* webpackChunkName: "pages/locations/index/create" */))
const _0a276895 = () => interopDefault(import('../pages/locations/index/_id/index.vue' /* webpackChunkName: "pages/locations/index/_id/index" */))
const _562a57d2 = () => interopDefault(import('../pages/locations/index/_id/edit.vue' /* webpackChunkName: "pages/locations/index/_id/edit" */))
const _71f86922 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _660e1d37 = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _83ce2736 = () => interopDefault(import('../pages/prices/index/_id/index.vue' /* webpackChunkName: "pages/prices/index/_id/index" */))
const _6927f73a = () => interopDefault(import('../pages/prices/index/_type/create.vue' /* webpackChunkName: "pages/prices/index/_type/create" */))
const _b29a0574 = () => interopDefault(import('../pages/printers/index.vue' /* webpackChunkName: "pages/printers/index" */))
const _4b713c95 = () => interopDefault(import('../pages/printers/index/create.vue' /* webpackChunkName: "pages/printers/index/create" */))
const _578c45b4 = () => interopDefault(import('../pages/printers/index/_id/index.vue' /* webpackChunkName: "pages/printers/index/_id/index" */))
const _76dee3f0 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _01f56814 = () => interopDefault(import('../pages/reports/index/_id/index.vue' /* webpackChunkName: "pages/reports/index/_id/index" */))
const _b99d406a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _105cea70 = () => interopDefault(import('../pages/services/index/create.vue' /* webpackChunkName: "pages/services/index/create" */))
const _35c905f9 = () => interopDefault(import('../pages/services/index/_id/index.vue' /* webpackChunkName: "pages/services/index/_id/index" */))
const _0e1eccca = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _6909b091 = () => interopDefault(import('../pages/subscription/index/create.vue' /* webpackChunkName: "pages/subscription/index/create" */))
const _68138906 = () => interopDefault(import('../pages/terminals.vue' /* webpackChunkName: "pages/terminals" */))
const _c7a671a4 = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _088617d0 = () => interopDefault(import('../pages/employee-schedules/index2.vue' /* webpackChunkName: "pages/employee-schedules/index2" */))
const _30cb29eb = () => interopDefault(import('../pages/pos/new.vue' /* webpackChunkName: "pages/pos/new" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _50df994b = () => interopDefault(import('../pages/pos/_customer/index.vue' /* webpackChunkName: "pages/pos/_customer/index" */))
const _b22c7936 = () => interopDefault(import('../pages/terminals' /* webpackChunkName: "" */))
const _5756eb3e = () => interopDefault(import('../pages/pos/_customer/pay.vue' /* webpackChunkName: "pages/pos/_customer/pay" */))
const _37edccd8 = () => interopDefault(import('../pages/pos/_customer/pay/payment.vue' /* webpackChunkName: "pages/pos/_customer/pay/payment" */))
const _271374c4 = () => interopDefault(import('../pages/pos/_customer/payments.vue' /* webpackChunkName: "pages/pos/_customer/payments" */))
const _67a68453 = () => interopDefault(import('../pages/pos/_customer/pickup.vue' /* webpackChunkName: "pages/pos/_customer/pickup" */))
const _c64fbec0 = () => interopDefault(import('../pages/pos/_customer/tickets.vue' /* webpackChunkName: "pages/pos/_customer/tickets" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/change-password",
    component: _42ada9c4,
    name: "change-password"
  }, {
    path: "/customers",
    component: _9d729cc4,
    name: "customers",
    children: [{
      path: "create",
      component: _631b1586,
      name: "customers-index-create"
    }, {
      path: ":id",
      component: _faa727e8,
      name: "customers-index-id"
    }]
  }, {
    path: "/employee-schedules",
    component: _8468f2ec,
    name: "employee-schedules"
  }, {
    path: "/employees",
    component: _5bdfe8ce,
    name: "employees",
    children: [{
      path: "create",
      component: _ded46fe6,
      name: "employees-index-create"
    }, {
      path: ":id",
      component: _e1633188,
      name: "employees-index-id"
    }]
  }, {
    path: "/location-select",
    component: _31fa571a,
    name: "location-select"
  }, {
    path: "/locations",
    component: _16ddd532,
    name: "locations",
    children: [{
      path: "create",
      component: _6fe22558,
      name: "locations-index-create"
    }, {
      path: ":id",
      component: _0a276895,
      name: "locations-index-id"
    }, {
      path: ":id/edit",
      component: _562a57d2,
      name: "locations-index-id-edit"
    }]
  }, {
    path: "/login",
    component: _71f86922,
    name: "login"
  }, {
    path: "/prices",
    component: _660e1d37,
    name: "prices",
    children: [{
      path: ":id",
      component: _83ce2736,
      name: "prices-index-id"
    }, {
      path: ":type/create",
      component: _6927f73a,
      name: "prices-index-type-create"
    }]
  }, {
    path: "/printers",
    component: _b29a0574,
    name: "printers",
    children: [{
      path: "create",
      component: _4b713c95,
      name: "printers-index-create"
    }, {
      path: ":id",
      component: _578c45b4,
      name: "printers-index-id"
    }]
  }, {
    path: "/reports",
    component: _76dee3f0,
    name: "reports",
    children: [{
      path: ":id",
      component: _01f56814,
      name: "reports-index-id"
    }]
  }, {
    path: "/services",
    component: _b99d406a,
    name: "services",
    children: [{
      path: "create",
      component: _105cea70,
      name: "services-index-create"
    }, {
      path: ":id",
      component: _35c905f9,
      name: "services-index-id"
    }]
  }, {
    path: "/subscription",
    component: _0e1eccca,
    name: "subscription",
    children: [{
      path: "create",
      component: _6909b091,
      name: "subscription-index-create"
    }]
  }, {
    path: "/terminals",
    component: _68138906,
    name: "terminals"
  }, {
    path: "/timesheets",
    component: _c7a671a4,
    name: "timesheets"
  }, {
    path: "/employee-schedules/index2",
    component: _088617d0,
    name: "employee-schedules-index2"
  }, {
    path: "/pos/new",
    component: _30cb29eb,
    name: "pos-new"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }, {
    path: "/pos/:customer",
    component: _50df994b,
    name: "pos-customer"
  }, {
    path: "/terminals/:slug",
    component: _b22c7936,
    name: "terminals-slug"
  }, {
    path: "/pos/:customer?/pay",
    component: _5756eb3e,
    name: "pos-customer-pay",
    children: [{
      path: "payment",
      component: _37edccd8,
      name: "pos-customer-pay-payment"
    }]
  }, {
    path: "/pos/:customer?/payments",
    component: _271374c4,
    name: "pos-customer-payments"
  }, {
    path: "/pos/:customer?/pickup",
    component: _67a68453,
    name: "pos-customer-pickup"
  }, {
    path: "/pos/:customer?/tickets",
    component: _c64fbec0,
    name: "pos-customer-tickets"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
