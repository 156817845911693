<script>
export default {
  name: 'AppMoney',
  props: {
    value: { type: [String, Number], default: 0 },
  },
  computed: {
    formattedValue() {
      const formattedNumber = Number.parseFloat((this.value * 0.01).toFixed(2))
      return formattedNumber.toLocaleString('US', {
        style: 'currency',
        currency: 'USD',
      })
    },
  },
}
</script>

<template>
  <span>{{ formattedValue }}</span>
</template>

<style scoped></style>
