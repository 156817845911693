export function state() {
  return {
    posMenuVisible: false,
    miniNavBar: false,
    customerSearchVisible: false,
  }
}

export const mutations = {
  setPosMenuVisible(state, isVisible) {
    state.posMenuVisible = isVisible
  },
  setCustomerSerarchVisibility: (state, visible) =>
    (state.customerSearchVisible = !!visible),
}

export const actions = {
  openCustomerSearchModal({ commit }) {
    commit('setCustomerSerarchVisibility', true)
  },
  closeCustomerSearchModal({ commit }) {
    commit('setCustomerSerarchVisibility', false)
  },
}
