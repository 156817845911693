<script>
export default {
  name: 'TerminalIcon',
  props: {
    number: { type: Number, default: 0 },
  },
}
</script>

<template>
  <svg
    viewBox="0 0 55 55"
    xmlns="http://www.w3.org/2000/svg"
    style="height: 1em"
  >
    <g
      stroke="currentColor"
      stroke-width="3.429"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <text
        v-if="number"
        class="text-sm"
        stroke-width="1px"
        fill="currentColor"
        style="font-family: Sans,sans-serif"
        text-anchor="middle"
        alignment-baseline="middle"
        stroke-linejoin="miter"
        x="28"
        y="22"
      >
        {{ number }}
      </text>
      <path
        d="M8.8 2h37.4C49.956 2 53 5.134 53 9v21c0 3.866-3.044 7-6.8 7H8.8C5.044 37 2 33.866 2 30V9c0-3.866 3.044-7 6.8-7zM14 53c0-4.419 6.046-8 13.5-8S41 48.581 41 53H14zM28 37v8"
      />
    </g>
  </svg>
</template>

<style scoped></style>
