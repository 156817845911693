export function state() {
  return {
    list: [],
    searchList: [],
    pagination: {},
  }
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.list = items
  },
  SET_SEARCH_ITEMS(state, items) {
    state.searchList = items
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  },
}

export const actions = {
  async searchFetchItems({ commit, state }, params = {}) {
    return this.$axios
      .$get('customers', {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_SEARCH_ITEMS', data.data)
        return Promise.resolve(state.searchList)
      })
      .catch(() => {})
  },
  async fetchItems({ commit, dispatch, state }, params = {}) {
    dispatch('wait/start', 'customers.index', { root: true })

    return this.$axios
      .$get('customers', {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        data.data.totalPages = data.meta.total
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'customers.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.index', { root: true })
      })
  },
  fetchItem({ dispatch }, id) {
    dispatch('wait/start', 'customers.show', { root: true })
    return this.$axios
      .$get(`/customers/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'customers.show', { root: true })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Customer Show',
          text: e.response.data.errors,
        })
        dispatch('wait/end', 'customers.show', { root: true })
        return Promise.reject(e)
      })
  },
  updateItem({ dispatch }, customer) {
    dispatch('wait/start', 'customers.edit', { root: true })
    return this.$axios
      .$put(
        `/customers/${customer.id}`,
        { ...customer },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Customer Edit',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        for (e.response.data.error in e.response.data.errors) {
          this.$notify({
            type: 'error',
            title: 'Customer Edit',
            text: e.response.data.errors[e.response.data.error][0],
          })
        }
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'customers.edit', { root: true })
      })
  },
  create({ dispatch }, customer) {
    dispatch('wait/start', 'customers.create', { root: true })
    return this.$axios
      .$post(
        `/customers`,
        { ...customer },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'customers.create', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Customer Create',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'customers.create',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'customers.create', { root: true })
        return Promise.reject(e)
      })
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'customers.delete', { root: true })
    return this.$axios
      .$delete(`/customers/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'customers.delete', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Customer Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'customers.delete',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'customers.delete', { root: true })
        return Promise.reject(e)
      })
  },
}
