<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
      validate(icon) {
        if (icon === true)

          console.error(`icon property expected String got ${typeof icon}`)
      },
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    svgIcon() {
      try {
        return require(`!!vue-svg-loader!../assets/icons/${this.icon}.svg`)
      }
      catch (error) {
        console.error(`Icon ${this.icon} not found`, error)
        return null
      }
    },
    iconStyles() {
      return {
        height: '1em',
        width: '1em',
        ...this.styles, // Merge additional styles with default styles
      }
    },
  },
}
</script>

<template>
  <component
    :is="svgIcon"
    class="inline-block fill-current"
    :style="iconStyles"
  />
</template>
