export function state() {
  return {
    itemList: [],
    totalItems: 0,
  }
}

export const mutations = {
  SET_ITEMS: (state, items) => (state.itemList = items),
  TOTAL_ITEMS: (state, totalItems) => (state.totalItems = totalItems),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'items.index', { root: true })

    return this.$axios
      .$get(`/display/items`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        commit('TOTAL_ITEMS', data.meta.total)
        dispatch('wait/end', 'items.index', { root: true })
        return Promise.resolve(state.itemList)
      })
      .catch(() => {
        dispatch('wait/end', 'items.index', { root: true })
      })
  },
}

export const getters = {}
