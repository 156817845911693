<script>
import TextInput from './common/TextInput'
import AppButton from './common/AppButton'
import Icon from './Icon'
import Avatar from '~/components/Avatar'

export default {
  name: 'ManagerLogin',
  components: { Icon, AppButton, TextInput, Avatar },
  data: () => ({
    load: false,
    opened: false,
    form: {
      email: 'rboesp@gmail.com',
      password: 'password',
      mode: 'manager',
    },
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.loggedIn
    },
    user() {
      return this.$auth.user
    },
  },
  methods: {
    toggle() {
      this.opened = !this.opened
    },
    async logout() {
      this.load = true
      try {
        await this.$auth.logout().then(() => {
          this.$auth.$storage.setUniversal('location_slug', null)
          this.$store.dispatch('tickets/clearOpenedTickets')
          this.$store.dispatch('terminals/clearSelectedTerminal')
          this.$store.dispatch('locations/clearSelectedLocation')
        })
      }
      finally {
        this.load = false
      }
    },
    async login() {
      try {
        const { slug } = this.$route.params
        this.load = true
        await this.$auth.loginWith('local', {
          data: { ...this.form, location_slug: slug },
        })
        await this.$auth.$storage.setUniversal('mode', this.form.mode)
        await this.$auth.$storage.setUniversal('location-slug', slug)

        this.toggle()
      }
      catch (e) {
        this.$notify({
          title: 'Woops',
          text: e.response.data.message,
          type: 'error',
        })
      }
      finally {
        this.load = false
      }
    },
  },
}
</script>

<template>
  <div>
    <div v-if="!user" class="relative z-50">
      <div
        v-show="opened"
        class="fixed top-0 left-0 w-full h-full bg-smoke-lighter"
      />
      <div
        v-show="opened"
        class="absolute bottom-0 left-0 p-8 mb-4 bg-white rounded w-68"
      >
        <a
          href="javascript:void(0)"
          class="absolute top-0 right-0 inline-block p-2 text-red focus:outline-none"
          @click="toggle"
        >
          <Icon icon="close" />
        </a>
        <h3
          class="mb-4 text-xl font-semibold leading-none text-center text-tundora"
        >
          Manager Login
        </h3>
        <TextInput
          v-model="form.email"
          name="email"
          label="Employee ID"
          class="mb-4"
        />
        <TextInput
          v-model="form.password"
          name="password"
          label="Password"
          class="mb-8"
          type="password"
        />
        <div class="text-center">
          <AppButton
            :busy="load"
            class="w-full pl-8 pr-8 text-xs font-semibold"
            @click.native="login"
          >
            Login
          </AppButton>
        </div>
        <div
          class="absolute w-8 h-8 rotate-45 bg-white rounded -bottom-8 left-45"
        />
      </div>
    </div>

    <div v-else class="flex items-center">
      <Avatar :src="user.avatar" class="block h-8 bg-white rounded-full shadow-md" />
      <AppButton
        :busy="load"
        variant="white"
        outline
        class="mx-4 text-xs font-semibold leading-tight text-grey-darker"
        @click.native="logout"
      >
        Logout
      </AppButton>
    </div>

    <AppButton
      v-if="!user"
      outline
      variant="white"
      class="text-xs font-semibold leading-none"
      @click.native="toggle"
    >
      Manager Login
    </AppButton>
  </div>
</template>
