export default async function ({ store, route, error }) {
  if (route.name != null) {
    const match = route.name.includes('subscription')
    if (match) {
      await store.dispatch('employees/getEmployee')
      const currentEmployee = store.state.employees.employee

      if (!currentEmployee.is_company_owner)
        onUnauthorized(error)
    }
  }
}

function onUnauthorized(error) {
  return error({
    statusCode: 401,
    message: 'Oh no! You are not authorized to access this resource.',
  })
}
