<script>
import Avatar from './Avatar'
import Icon from './Icon'
import AppMoney from './AppMoney'

export default {
  name: 'HeaderCustomerInfo',
  components: { AppMoney, Avatar, Icon },
  computed: {
    customers() {
      return this.$store.getters['tickets/recentCustomers']
    },
    isPosMenuVisible() {
      return this.$store.state.ui.posMenuVisible
    },
    inPos() {
      return this.$route.path.includes('/pos')
    },
  },
  methods: {
    displayName(customer) {
      const { first_name: firstName, last_name: lastName } = customer
      return `${firstName.charAt(0)}${lastName.charAt(0)}`
    },
    isActive(customer) {
      return (
        customer.id === (this.$store.state.tickets.currentCustomer || {}).id
      )
    },
    async onRemove({ id }) {
      await this.$store.dispatch('tickets/clearCustomerTickets', {
        id,
        redirect: this.inPos,
        openSearch: true,
        removeCustomer: true,
      })
    },
    getOwedAmount(customer) {
      return this.$store.getters['tickets/getOwedAmount'](customer)
    },
  },
}
</script>

<template>
  <div class="relative flex items-center">
    <nuxt-link
      v-for="customer in customers"
      :key="customer.id"
      :to="`/pos/${customer.id}`"
      class="relative flex items-center h-full px-6 border-r outline-none focus:outline-none"
      :class="{ 'first-child:border-l': !isPosMenuVisible }"
      active-class="bg-off-green"
    >
      <Avatar
        class="hidden lg:block"
        :src="customer.avatar"
      />
      <div class="flex flex-col ml-2">
        <span
          class="mb-1 text-xs font-semibold capitalize lg:text-lg text-grey-darker"
        >
          {{ displayName(customer) }}
        </span>
        <span class="text-xs font-semibold lg:text-sm text-red">
          <AppMoney :value="getOwedAmount(customer)" />
        </span>
      </div>
      <Icon
        icon="remove"
        class="absolute top-0 right-0 mt-2 mr-2 text-xxs text-red"
        @click.native.prevent="onRemove(customer)"
      />
    </nuxt-link>
  </div>
</template>
