export default function ({ route, error, store }) {
  const { auth } = route.meta.slice().pop()
  if (!checkRoles(auth, store))
    onUnauthorized(error)
}

function checkRoles(auth, store) {
  if (!auth || (!!auth && !auth.roles))
    return true
  return (
    !!auth
    && !!auth.roles
    && auth.roles.includes(store.getters.currentLocation.role)
  )
}

// const checkEmployeeOnlyRoutes = (route, store) => {
//   return !(route.path.startsWith('/pos') && !store.getters.isEmployee)
// }

function onUnauthorized(error) {
  return error({
    statusCode: 401,
    message: 'Oh no! You are not authorized to access this resource.',
  })
}
