import Vue from 'vue'
import Vuelidate from 'vuelidate'

const $serverErrors = new Vue({
  data: () => ({
    errors: {},
  }),
  methods: {
    inject($key, errors = []) {
      if (typeof errors !== 'object') {
        console.warn(`server error must be object ${typeof errors} received`)
        return
      }
      this.$set(this.errors, $key, errors)
    },
    reset(key = null) {
      if (key === null)
        this.errors = {}
      else this.$delete(this.errors, key)
    },
    /**
     * @param $v Veuelidate filed
     * todo Improve code readability
     */
    parseErrors($v) {
      if (!$v)
        return []
      return Object.keys($v.$params || {})
        .map((rule) => {
          const { server } = $v.$params[rule]
          const valid = $v[rule]
          const errorObj = { valid, server, message: rule }
          if (server && !valid) {
            const { key, field } = $v.$params[rule]
            errorObj.message = this.errors[key][field][0]
          }
          return errorObj
        })
        .filter(error => !error.valid)
    },
  },
})

const mixin = {
  computed: {
    $serverErrors: () => $serverErrors,
  },
  methods: {
    $parseErrors: $serverErrors.parseErrors,
  },
}

export default function ({ app }) {
  Vue.use(Vuelidate)
  Vue.mixin(mixin)

  app.parseServerErrors = $serverErrors.parseErrors
  app.injectServerErrors = $serverErrors.inject
  app.resetServerErrors = $serverErrors.reset
}
