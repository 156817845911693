<script>
import TerminalIcon from '~/components/Terminal/TerminalIcon'
import TerminalPassword from '~/components/Terminal/TerminalPassword'

export default {
  components: { TerminalIcon, TerminalPassword },
  data() {
    return {
      terminalPasswordPopup: {
        terminal: {},
        show: false,
      },
    }
  },
  computed: {
    terminal() {
      return this.$store.getters['terminals/actualTerminal']
    },
  },
  methods: {
    logoutTerminal() {
      this.terminalPasswordPopup = {
        terminal: this.terminal,
        show: true,
      }
    },
    async logout() {
      await this.$auth.logout().then(() => {
        this.$auth.$storage.setUniversal('location_slug', null)
        this.$store.dispatch('tickets/clearOpenedTickets')
        this.$store.dispatch('terminals/clearSelectedTerminal')
        this.$store.dispatch('locations/clearSelectedLocation')
      })
    },
    hide() {
      this.terminalPasswordPopup.show = false
    },
  },
}
</script>

<template>
  <span
    v-if="terminal"
    class="absolute bottom-0 flex flex-wrap items-center w-full mb-4 select-none text-grey"
  >
    <div class="flex justify-center w-full">
      <button @click="logoutTerminal">
        <TerminalIcon :number="terminal.id" />
      </button>
    </div>
    <div class="w-full mt-2 text-center text-xxs">{{ terminal.name }}</div>
    <TerminalPassword
      v-if="terminalPasswordPopup.show"
      :terminal="terminalPasswordPopup.terminal"
      :release="true"
      :redirect-to-terminals="true"
      @hide="hide"
    />
  </span>
</template>
