<script>
import TheHeader from '../components/TheHeader'
import TheNavBar from '../components/TheNavBar'
import ThePosNavBar from '../components/ThePosNavBar'
import CustomerSearchModal from '../components/CustomerSearch/CustomerSearchModal'

export default {
  components: {
    CustomerSearchModal,
    ThePosNavBar,
    TheHeader,
    TheNavBar,
  },
  computed: {
    miniNavBar() {
      return (
        this.$store.state.ui.miniNavBar || this.$store.state.ui.posMenuVisible
      )
    },
    navBarClassName() {
      const className = {}
      className['w-48'] = !this.miniNavBar
      className['w-16'] = this.miniNavBar
      return className
    },
  },
}
</script>

<template>
  <div class="flex overflow-hidden">
    <TheNavBar class="z-40" :class="navBarClassName" :mini="miniNavBar" />
    <ThePosNavBar class="z-30 w-48" />
    <div
      class="relative flex flex-wrap flex-grow w-full h-screen pt-16 overflow-hidden"
    >
      <TheHeader class="absolute z-10 w-full -mt-16" />
      <div class="justify-center w-full h-full p-4 overflow-auto xl:p-8">
        <div class="container mx-auto mb-4">
          <nuxt />
        </div>
      </div>
    </div>
    <portal-target name="modals" />
    <portal-target name="camera-modal" />
    <CustomerSearchModal visible />
  </div>
</template>
