<script>
import debounce from 'lodash.debounce'
import TextInput from '../common/TextInput'
import AppButton from '../common/AppButton'
import CustomerSearchCard from './CustomerSearchCard'
import CustomerSearchResult from './CustomerSearchResult'

export default {
  name: 'CustomerSearch',
  components: {
    AppButton,
    CustomerSearchResult,
    TextInput,
    CustomerSearchCard,
  },
  props: {
    onClose: {
      default: () => () => {
        /* NoOp */
      },
      type: Function,
    },
  },
  data: () => ({
    keyword: '',
    selected: null,
    debouncedSearch: () => null,
  }),
  computed: {
    customers() {
      return this.$store.state.customers.searchList
    },
  },
  watch: {
    keyword: {
      handler() {
        this.debouncedSearch()
      },
      deep: true,
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 500)
  },

  async mounted() {
    await this.search()
  },

  methods: {
    async createTicket() {
      try {
        const { id } = this.selected
        this.$store.dispatch('wait/start', 'tickets.setCurrentCustomer')
        await this.$store.dispatch('tickets/setCurrentCustomer', id)
        const done = () => {
          this.$store.dispatch('wait/end', 'tickets.setCurrentCustomer')
        }

        const onSuccess = () => this.onRouteChange().then(done)

        this.$router.push(`/pos/${id}`, onSuccess, done)
      }
      catch (error) {
        console.log('error', error)
      }
      finally {
        this.$store.dispatch('wait/end', 'tickets.setCurrentCustomer')
      }
    },
    async search() {
      this.$store.dispatch('wait/start', 'pos.new.search')
      try {
        await this.$store.dispatch('customers/searchFetchItems', {
          keyword: this.keyword,
        })
      }
      finally {
        this.$store.dispatch('wait/end', 'pos.new.search')
      }
    },
    onRouteChange() {
      return Promise.resolve(this.onClose())
    },
  },
}
</script>

<template>
  <CustomerSearchCard>
    <TextInput
      v-model="keyword"
      label="Last Name | Ticket # | Phone #"
      class="mb-4"
    />
    <CustomerSearchResult v-model="selected" :customers="customers" />
    <div class="flex">
      <div class="w-1/2 pr-4">
        <AppButton
          variant="grey"
          class="w-full text-xs font-semibold"
          :busy="$wait.waiting('pos.new.search')"
          @click.native="search"
        >
          Filter
        </AppButton>
      </div>
      <div class="w-1/2 pl-4">
        <AppButton
          class="w-full text-xs font-semibold"
          :disabled="!selected"
          :busy="$wait.waiting('tickets.setCurrentCustomer')"
          @click.native="createTicket"
        >
          Go
        </AppButton>
      </div>
    </div>
  </CustomerSearchCard>
</template>

<style scoped></style>
