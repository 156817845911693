export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, services) => (state.list = services),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'services.index', { root: true })

    return this.$axios
      .$get(`/services`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        data.data.totalPages = data.meta.total
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'services.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'services.index', { root: true })
      })
  },
  fetchItem({ dispatch }, id) {
    dispatch('wait/start', 'services.show', { root: true })
    return this.$axios
      .$get(`/services/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'services.show', { root: true })
        this.$notify({
          type: 'success',
          title: 'Service Show',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Service Show',
          text: e.response.data.errors,
        })
        dispatch('wait/end', 'services.show', { root: true })
        return Promise.reject(e)
      })
  },
  create({ dispatch }, service) {
    dispatch('wait/start', 'services.create', { root: true })
    return this.$axios
      .$post(
        `/services`,
        { ...service },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'services.create', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Service Create',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.$notify({
            type: 'error',
            title: 'Service Create',
            text: e.response.data.error,
          })
        }
        if (e.response && e.response.status === 422) {
          console.log(e.response.data)
          this.$notify({
            type: 'error',
            title: 'Service Create',
            text: e.response.data.errors.name[0],
          })
          this.app.injectServerErrors('services.create', e.response.data.errors)
        }
        dispatch('wait/end', 'services.create', { root: true })
        return Promise.reject(e)
      })
  },
  updateItem({ dispatch }, service) {
    dispatch('wait/start', 'services.edit', { root: true })
    return this.$axios
      .$put(
        `/services/${service.id}`,
        { ...service },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Service Edit',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Service Edit',
          text: e.response.data.errors.name[0],
        })
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'services.edit', { root: true })
      })
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'services.delete', { root: true })
    return this.$axios
      .$delete(`/services/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'services.delete', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Service Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422)
          this.app.injectServerErrors('services.delete', e.response.data.errors)

        dispatch('wait/end', 'services.delete', { root: true })
        return Promise.reject(e)
      })
  },
}
