// eslint-disable @typescript-eslint/no-unused-vars -- temporary unused

export default async function ({ store, route, redirect }) {
  const isLoggedIn = store.state.auth.loggedIn
  const hasSelectedLocation = store.state.locations.selected
  const hasSelectedTerminal = store.state.terminals.selected
  const posRoute = route.path.startsWith('/pos')

  if (isLoggedIn && posRoute && !hasSelectedLocation && !hasSelectedTerminal) {
    return redirect('/location-select')
  }
}
