const middleware = {}

middleware['auth-route'] = require('../middleware/auth-route.js')
middleware['auth-route'] = middleware['auth-route'].default || middleware['auth-route']

middleware['authorize'] = require('../middleware/authorize.js')
middleware['authorize'] = middleware['authorize'].default || middleware['authorize']

middleware['check-current-customer'] = require('../middleware/check-current-customer.js')
middleware['check-current-customer'] = middleware['check-current-customer'].default || middleware['check-current-customer']

middleware['check-terminal'] = require('../middleware/check-terminal.js')
middleware['check-terminal'] = middleware['check-terminal'].default || middleware['check-terminal']

middleware['owner'] = require('../middleware/owner.js')
middleware['owner'] = middleware['owner'].default || middleware['owner']

middleware['side-bar-toggle'] = require('../middleware/side-bar-toggle.js')
middleware['side-bar-toggle'] = middleware['side-bar-toggle'].default || middleware['side-bar-toggle']

export default middleware
