export default function ({ $axios, store, redirect }) {
  $axios.interceptors.request.use((config) => {
    const companyId = localStorage.getItem('company-id')
    const token = store.$auth.getToken('local')

    if (companyId)
      config.headers.common['X-Company-Id'] = companyId

    if (token)
      config.headers.common.Authorization = `Bearer ${token}`

    return { ...config }
  })

  $axios.onError((error) => {
    const code = Number.parseInt(error.response && error.response.status)

    if (code === 403) {
      redirect('/location-select')
    }
  })
}
