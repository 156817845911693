export function state() {
  return {
    list: [],
    totalItems: '',
    employee: '',
  }
}

export const mutations = {
  SET_ITEMS: (state, items) => (state.list = items),
  TOTAL_ITEMS: (state, totalItems) => (state.totalItems = totalItems),
  SET_EMPLOYEE: (state, employee) => (state.employee = employee),
}

export const actions = {
  async fetchItems({ commit, dispatch, rootState }, params) {
    dispatch('wait/start', 'employees.index', { root: true })

    return this.$axios
      .$get(`/employees`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
          'X-subdomain': rootState.locations.selected ? rootState.locations.selected.slug : null,
        },
      })
      .then((data) => {
        let company = ''
        if (localStorage.getItem('subdomain'))
          company = localStorage.getItem('subdomain')

        // eslint-disable-next-line node/prefer-global/process
        if (company === process.env.DEMO_COMPANY)
          data.meta.total = data.meta.total - 1

        commit('SET_ITEMS', data.data)
        commit('TOTAL_ITEMS', data.meta.total - 1)
        dispatch('wait/end', 'employees.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'employees.index', { root: true })
      })
  },
  fetchItem({ dispatch }, id) {
    dispatch('wait/start', 'employees.show', { root: true })
    return this.$axios
      .$get(`/employees/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'employees.show', { root: true })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Employee Show',
          text: e.response.data.errors,
        })
        dispatch('wait/end', 'employees.show', { root: true })
        return Promise.reject(e)
      })
  },
  create({ dispatch }, employee) {
    dispatch('wait/start', 'employees.create', { root: true })
    return this.$axios
      .$post(
        `/employees`,
        { ...employee },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'employees.create', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Employee Create',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.$notify({
            type: 'error',
            title: 'Employee Create',
            text: e.response.data.error,
          })
        }
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'employees.create',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'employees.create', { root: true })
        return Promise.reject(e)
      })
  },
  updateItem({ dispatch }, employee) {
    dispatch('wait/start', 'employees.edit', { root: true })
    return this.$axios
      .$put(
        `/employees/${employee.id}`,
        { ...employee },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Employee Edit',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.$notify({
            type: 'error',
            title: 'Employee Create',
            text: e.response.data.error,
          })
        }
        for (e.response.data.error in e.response.data.errors) {
          this.$notify({
            type: 'error',
            title: 'Employee Edit',
            text: e.response.data.errors[e.response.data.error][0],
          })
        }
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'employees.edit', { root: true })
      })
  },
  updateOnboarding({ dispatch }, payload) {
    return this.$axios
      .$post(
        `/auth/update_onboarding`,
        { ...payload },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Onboard Update',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          for (e.response.data.error in e.response.data.errors) {
            this.$notify({
              type: 'error',
              title: 'Password Update',
              text: e.response.data.errors[e.response.data.error][0],
            })
          }
        }
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'employees.edit', { root: true })
      })
  },
  updatePassword({ dispatch }, payload) {
    return this.$axios
      .$post(
        `/auth/reset_password`,
        { ...payload },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Password Update',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          for (e.response.data.error in e.response.data.errors) {
            this.$notify({
              type: 'error',
              title: 'Password Update',
              text: e.response.data.errors[e.response.data.error][0],
            })
          }
        }
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'employees.edit', { root: true })
      })
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'employees.delete', { root: true })
    return this.$axios
      .$delete(`/employees/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'employees.delete', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Employee Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'employees.delete',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'employees.delete', { root: true })
        return Promise.reject(e)
      })
  },
  getEmployee({ commit }) {
    return this.$axios
      .$get(`/auth/me`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_EMPLOYEE', data.data)
        return Promise.resolve(state.employee)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  fetchTimeEntries({ dispatch }, { employeeId, locationId, isManager }) {
    dispatch('wait/start', 'employee.fetchTimeEntries', { root: true })

    return this.$axios
      .$get('/timesheet/today', {
        params: {
          employee_id: employeeId,
          location_id: locationId,
          is_manager: isManager,
        },
      })
      .then((data) => {
        dispatch('wait/end', 'employee.fetchTimeEntries', { root: true })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        dispatch('wait/end', 'employee.fetchTimeEntries', { root: true })
        return Promise.reject(e)
      })
  },
}

export const getters = {
  trialEndDate: state => state.employee.trial_ends_at,
}
