export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, items) => (state.list = items),
  ADD_ITEM: (state, item) => state.list.push(item),
}

export const actions = {
  fetchItems({ commit, dispatch }, params) {
    dispatch('wait/start', 'items.index', { root: true })

    return this.$axios
      .$get(`/items`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'items.index', { root: true })
        return Promise.resolve(data.data)
      })
      .catch(() => {
        dispatch('wait/end', 'items.index', { root: true })
      })
  },
  fetchItem({ dispatch }, id) {
    dispatch('wait/start', 'items.show', { root: true })
    return this.$axios
      .$get(`/items/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Item Show',
          text: data.message,
        })
        dispatch('wait/end', 'items.show', { root: true })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Item Show',
          text: e.response.data.errors,
        })
        dispatch('wait/end', 'items.show', { root: true })
        return Promise.reject(e)
      })
  },
  create({ dispatch }, item) {
    dispatch('wait/start', 'items.create', { root: true })
    return this.$axios
      .$post(
        `/items`,
        { ...item },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'items.create', { root: true })
        this.$notify({
          type: 'success',
          title: 'Item Create',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.$notify({
            type: 'error',
            title: 'Item Create',
            text: e.response.data.errors.name[0],
          })
          this.app.injectServerErrors('items.create', e.response.data.errors)
        }
        dispatch('wait/end', 'items.create', { root: true })
        return Promise.reject(e)
      })
  },
  updateItem({ dispatch }, item) {
    dispatch('wait/start', 'items.edit', { root: true })
    return this.$axios
      .$put(
        `/items/${item.id}`,
        { ...item },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Item Edit',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Item Edit',
          text: e.response.data.errors,
        })
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'items.edit', { root: true })
      })
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'items.delete', { root: true })
    return this.$axios
      .$delete(`/items/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'items.delete', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Items Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422)
          this.app.injectServerErrors('items.delete', e.response.data.errors)

        dispatch('wait/end', 'items.delete', { root: true })
        return Promise.reject(e)
      })
  },
  sortItems(state, items) {
    console.log('state: ', state)
    return this.$axios.$post(
      `/sort/items`,
      { ...items },
      {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      },
    )
  },
  localAddItem({ commit, dispatch }, item) {
    dispatch('wait/start', 'items.index', { root: true })
    commit('ADD_ITEM', item)
    dispatch('wait/end', 'items.index', { root: true })
  },
}

export const getters = {
  storeItems: (state) => {
    const items
      = state.list.length > 0
        ? state.list.filter(item => item.type === 'item')
        : []
    return items
  },
  storeAddons: (state) => {
    return state.list.length > 0
      ? state.list.filter(item => item.type === 'addon')
      : []
  },
}
