import Vue from 'vue'
import Notifications from 'vue-notification'

// eslint-disable-next-line no-empty-pattern
export default function ({}, inject) {
  Vue.use(Notifications)
  const notify = Vue.notify
  const group = 'none'
  delete Vue.prototype.notify
  delete Vue.prototype.$notify

  inject('notify', (config) => {
    notify({ ...config, group })
  })

  const notifications = new Vue({
    render(h) {
      return h('notifications', { props: { group, position: 'top right' } })
    },
  }).$mount()

  document.body.appendChild(notifications.$el)
}
