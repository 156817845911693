<script>
export default {
  name: 'Card',
}
</script>

<template>
  <div class="flex flex-col bg-white shadow-lg rounded-md">
    <slot />
  </div>
</template>
