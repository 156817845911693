<script>
import uploadIcon from '@/assets/images/upload-icon.png'
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import DefaultAvatar from '!!vue-svg-loader!../assets/icons/default-avatar.svg'

export default {
  inheritAttrs: false,
  props: {
    src: { type: String, default: null },
    placeholder: { type: String, default: 'Avatar' },
  },
  data: () => ({
    avatar: uploadIcon,
    isImageBroken: false,
  }),
  computed: {
    currentAvatar() {
      if (this.isImageBroken)
        return DefaultAvatar

      return this.avatar
    },
    svgIcon() {
      try {
        return require(`!!vue-svg-loader!../assets/icons/default-avatar.svg`)
      }
      catch (error) {
        console.error(`Icon default-avatar not found`, error)
        return null
      }
    },
  },
  watch: {
    src: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isImageBroken = false
          this.avatar = value

          return
        }

        this.handleImageError()
      },
    },
  },
  methods: {
    handleImageError() {
      this.isImageBroken = true
      this.avatar = null
    },
  },
}
</script>

<template>
  <div>
    <img
      v-if="!isImageBroken"
      v-bind="$attrs"
      :src="currentAvatar"
      class="object-cover w-8 h-8 rounded-full"
      @error="handleImageError"
    >
    <component
      :is="svgIcon"
      v-if="isImageBroken"
      class="object-cover w-8 h-8 text-black rounded-full"
      v-bind="$attrs"
    />
  </div>
</template>
