export function state() {
  return {
    addOnList: [],
    totalAddOns: 0,
  }
}

export const mutations = {
  SET_ADDON: (state, addons) => (state.addOnList = addons),
  TOTAL_ADDONS: (state, totalAddOns) => (state.totalAddOns = totalAddOns),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'items.index', { root: true })

    return this.$axios
      .$get(`/display/addons`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ADDON', data.data)
        commit('TOTAL_ADDONS', data.meta.total)
        dispatch('wait/end', 'items.index', { root: true })
        return Promise.resolve(state.addOnList)
      })
      .catch(() => {
        dispatch('wait/end', 'items.index', { root: true })
      })
  },
}

export const getters = {}
