export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, tickets) => (state.list = tickets),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, id) {
    dispatch('wait/start', 'customers.tickets.index', { root: true })

    return this.$axios
      .$get(`/customers/${id}/tickets`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'customers.tickets.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.tickets.index', { root: true })
      })
  },
}
