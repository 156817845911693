export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, payments) => (state.list = payments),
}

export const actions = {
  async create({ dispatch, rootState }, payment) {
    dispatch('wait/start', 'payments.create', { root: true })

    try {
      const response = await this.$axios.$post(
        `/payments`,
        {
          ...payment,
          terminal_id: rootState.terminals.selected.id,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )

      this.$notify({
        type: 'success',
        title: 'Payment Create',
        text: response.message,
      })

      return response.data
    }
    catch (e) {
      if (e.response && e.response.status === 422)
        this.app.injectServerErrors('payments.create', e.response.data.errors)

      dispatch('wait/end', 'payments.create', { root: true })
      return Promise.reject(e)
    }
    finally {
      dispatch('wait/end', 'payments.create', { root: true })
    }
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'payments.delete', { root: true })
    return this.$axios
      .$delete(`/payments/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'payments.delete', { root: true })
        this.$notify({
          type: 'success',
          title: 'Ticket Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422)
          this.app.injectServerErrors('payments.delete', e.response.data.errors)

        dispatch('wait/end', 'payments.delete', { root: true })
        return Promise.reject(e)
      })
  },
}
