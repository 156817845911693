export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, orders) => (state.list = orders),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, id) {
    dispatch('wait/start', 'customers.orders.index', { root: true })

    return this.$axios
      .$get(`/customers/${id}/orders`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'customers.orders.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.orders.index', { root: true })
      })
  },
}

export const getters = {
  userOrder: (state) => {
    return state.list.map((order) => {
      const { tickets } = order
      order.qty = tickets.reduce((itemTotal, ticket) => {
        return itemTotal + ticket.items.length
      }, 0)
      return order
    })
  },
}
