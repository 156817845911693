<script>
import AppModal from '../Modal/AppModal'
import CustomerSearch from './CustomerSearch'

export default {
  components: { AppModal, CustomerSearch },
  computed: {
    visible() {
      return this.$store.state.ui.customerSearchVisible
    },
  },
  methods: {
    onModalClose() {
      return this.$store.dispatch('ui/closeCustomerSearchModal')
    },
  },
}
</script>

<template>
  <AppModal
    v-if="visible"
    :heading="false"
    class="flex justify-center w-full text-tundora"
    width-class="w-68"
    @close="onModalClose"
  >
    <div>
      <CustomerSearch :on-close="onModalClose" />
    </div>
  </AppModal>
</template>
