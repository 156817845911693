export default async function ({ store, route, params }) {
  const currentCustomer = store.state.tickets.currentCustomer
  if (route.name != null) {
    const match
      = route.name.includes('pos-customer')
      && (!currentCustomer || currentCustomer.id !== Number.parseInt(params.customer))

    if (match)
      await store.dispatch('tickets/setCurrentCustomer', params.customer)
  }
}
