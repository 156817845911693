<script>
import { mainMenu } from '../utils/menus'
import Logo from '../assets/images/logo.svg'
import LogoSmall from '../assets/images/logo-small.svg'
import TerminalNavBar from './Terminal/TerminalNavBar'
import NavMenuItem from './NavMenuItem'

export default {
  name: 'TheNavBar',
  components: { NavMenuItem, TerminalNavBar },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoClassName() {
      return {
        'p-5 pt-4': !this.mini,
      }
    },
    logo() {
      return this.mini ? LogoSmall : Logo
    },
    mainMenu() {
      return mainMenu({ store: this.$store, $auth: this.$auth }).map(item => ({
        ...item,
        mini: this.mini,
      }))
    },
  },
}
</script>

<template>
  <div class="relative h-full h-screen overflow-hidden font-sans font-medium bg-white shadow-lg">
    <div :class="{ 'pt-4': !mini }">
      <nuxt-link to="/" class="block" :class="logoClassName">
        <img alt="" :src="logo">
      </nuxt-link>
    </div>
    <ul class="overflow-y-auto">
      <NavMenuItem v-for="(item, index) in mainMenu" :key="index" :item="item" />
    </ul>
    <TerminalNavBar />
  </div>
</template>

<style scoped>
li .nuxt-link-exact-active {
  background: #f4f4f5;
  color: #1db488;
}

li .nuxt-link-exact-active span {
  color: #4a4a4a;
  font-weight: 600;
}
</style>
