import { ROLES } from '../utils/constants'
import { isNullOrUndefined } from '../utils'

export function state() {
  return {
    mode: 'none',
  }
}

export const mutations = {
  setMode: (state, mode) => (state.mode = mode),
}

export const actions = {
  clockInOut({ dispatch }, employee) {
    console.log(employee)
    dispatch('wait/start', 'clock-in-out')

    return this.$axios
      .$post('/v1/timesheet/clock-in-out', employee)
      .then((res) => {
        dispatch('wait/end', 'clock-in-out')
        this.$notify({
          type: 'success',
          title: 'Clock In/Out Successfull',
          text: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch('wait/end', 'clock-in-out')
        this.$notify({
          type: 'error',
          title: 'Employee Show',
          text: err,
        })
      })
  },
}

export const getters = {
  currentLocation: (state) => {
    const { user } = state.auth
    const { selected: selectedLocation, list: locations } = state.locations
    const { location_slug: locationSlug } = user

    if (!user)
      return null

    if (getters.isOwner && selectedLocation) {
      return {
        ...locations.find(loc => loc.slug === selectedLocation.slug),
        role: ROLES.MANAGER,
      }
    }

    if (selectedLocation) {
      return user.locations.find(loc => loc.slug === selectedLocation.slug)
    }

    const location = user.locations.find(loc => loc.slug === locationSlug)
    if (isNullOrUndefined(location))
      return null

    return location
  },

  isOwner: (state) => {
    if (!state.auth.loggedIn)
      return false

    return state.auth.user.is_company_owner
  },

  isManager: (state, getters) => {
    if (!state.auth.loggedIn)
      return false
    return getters.currentLocation
      ? getters.currentLocation.role === ROLES.MANAGER
      : false
  },

  isEmployee: (state, getters) => {
    if (!state.auth.loggedIn)
      return false
    return getters.currentLocation
      ? getters.currentLocation.role === ROLES.EMPLOYEE
      : false
  },
}
